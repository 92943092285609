<template>
    <div id="smart-tag">
        <div class="header">
            <Row>
                <Col span="12">
                    <h1 class="title">价签屏
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="12" class="clearfix">
                    <Button v-allow="'device'" class="float-right" type="primary" @click="handleRepeatersModal">中继管理</Button>
                    <Input v-model="query" class="float-right margin-right" clearable placeholder="搜索屏幕名称/屏幕ID" style="width: auto;" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <div class="action-bar">
                <template v-if="checkAllGroup.length === 0">
                    <Dropdown class="dropdown status" trigger="click" placement="bottom-start" >
                        <Icon v-if="status" class="close" type="ios-close-circle" @click="clearStatusList" />
                        <Button type="text">
                            状态<span v-if="status">:{{ status.text._ }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="(item, index) in statusList" :key="index" @click.native="handleStatusList(item)">{{ item.text._ }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown class="dropdown model" trigger="click" placement="bottom-start">
                        <Icon v-if="model" class="close" type="ios-close-circle" @click="clearModelList" />
                        <Button type="text">
                            产品类型<span v-if="model">:{{ model.text._ }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <DropdownItem v-for="(item, index) in modelList" :key="index" @click.native="handleModelList(item)">{{ item.text._ }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown class="dropdown" v-if="is_org" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                        <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                        <Button type="text">
                            组织架构
                            <div v-if="nodeList.length" class="tree line-overflow">
                                <span>:</span>
                                <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                    <span v-if="index !== nodeList.length - 1">,</span>
                                </span>
                            </div>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                        </DropdownMenu>
                    </Dropdown>
                </template>
                <template v-allow="'device'" v-else>
                     <Button class="margin-right" type="primary" @click="handleTransfer">屏幕转移</Button>
                </template>
                <Checkbox
                    v-allow="'device'"
                    v-show="list.length !== 0"
                    class="checkbox-all float-right"
                    :indeterminate="indeterminate"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">全选</Checkbox>
            </div>
            <no-data v-show="list.length === 0" text="暂无价签"></no-data>
            <div v-show="list.length > 0" class="check-group-wrap">
                <!-- <div class="check-group"> -->
                <CheckboxGroup class="check-group" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                    <div class="checkbox-wrap" v-for="item in list" :key="item.rowid" >
                        <div class="img-wrap-block">
                            <div :class="['device-status', item.state]">
                                <span class="text">{{ item.state_text._ }}</span>
                            </div>
                            <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                                <img class="image-center" :src="`/cym/${item.cover_uri}`">
                            </div>
                        </div>
                        <div class="mask"></div>
                        <div class="device-info">
                            <div class="device-name name-wrap">
                                <div class="name line-overflow">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="device-id line-overflow">ID:&nbsp;{{ item.serial }}</div>
                            <div v-if="item.department_name && is_org" class="device-site line-overflow">{{ item.department_name }}</div>
                        </div>
                        <Checkbox v-allow="'device'" :class="['checkbox', checkAllGroup.length === 0 ? '' : 'checked']" :label="item.rowid" ><span>&nbsp;</span></Checkbox>
                        <Dropdown v-allow="'device'" v-if="checkAllGroup.length === 0" class="more" placement="bottom-start" trigger="click" >
                            <a href="javascript:void(0)">
                                <Icon class="icon-more" type="md-more" color="#2d2d2d" :size="22" />
                            </a>
                            <DropdownMenu slot="list" >
                                <DropdownItem v-allow="'device'" @click.native="handleEdit(item)">编辑</DropdownItem>
                                <DropdownItem v-allow="'deploy'" @click.native="handleReplaceData(item.rowid)">换绑数据</DropdownItem>
                                <DropdownItem v-allow="'deploy'" @click.native="handleUnbind(item.rowid)">解绑数据</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </CheckboxGroup>
                <Spin fix size="large" v-if="loading"></Spin>
                <Page
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
        </div>
        <edit-modal
            :show="editModalShow"
            :editInfo="editInfo"
            :department_id="department_id"
            :is_org="is_org"
            @cancel="handleEditCancel"
            @confirm="handleEditSubmit"></edit-modal>
        <replace-data-modal
            :show="showReplaceDataModal"
            :terminalId="terminalId"
            @cancel="handleReplaceDataCancel"
            @confirm="handleReplaceDataConfirm"></replace-data-modal>
        <repeaters-modal
            :show="showRepeatersModal"
            :is_org="is_org"
            :tree_data="tree_data"
            @close="handleRepeatersModalClose"></repeaters-modal>
        <transfer-modal
            :show="transferModalShow"
            :terminals="checkAllGroup"
            @cancel="handleTransferCancel"
            @confirm="handleTransferConfirm"></transfer-modal>
    </div>  
</template>
<script>
    import { dropdownTree, checkbox } from '@/mixins/index'
    import noData from '@/views/components/noData'
    import editModal from './components/editModal'
    import replaceDataModal from './components/replaceDataModal'
    import repeatersModal from './components/repeatersModal'
    import transferModal from '@/views/screens/mediaScreens/components/transfer'

    export default {
        name: "",
        mixins: [dropdownTree, checkbox],
        components: {
            noData,
            editModal,
            replaceDataModal,
            repeatersModal,
            transferModal
        },
        props: {},
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 40,
                query: '',
                loading: false,
                list: [],
                data: [],
                nodeList: [],
                treeList: [],
                editModalShow: false,
                editInfo: {},
                terminalId: null,
                showReplaceDataModal: false,
                showRepeatersModal: false,
                modelList: [],
                model: null,
                status: null,
                statusList: [],
                checkAllGroup: [],
                indeterminate: false,
                checkAll: false,
                transferModalShow: false
            }
        },
        computed: {
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            tree_data() {
                return [this.$store.state.user_tree]
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
        },
        methods: {
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            handleTransfer() {
                this.transferModalShow = true
            },
            handleTransferCancel() {
                this.transferModalShow = false
            },
            handleTransferConfirm() {
                this.indeterminate = false
                this.checkAllGroup = []
                this.checkAll = false
                this.getData()
            },
            handleRepeatersModal() {
                this.showRepeatersModal = true
            },
            handleRepeatersModalClose() {
                this.showRepeatersModal = false
                this.getData()
            },
            handleStatusList(item) {
                this.current = 1
                this.status = item
                this.getData()
            },
            clearStatusList() {
                this.current = 1
                this.status = null
                this.getData()
            },
            getStatusList() {
                this.$api.get('terminals/status/list').then(({ data }) => {
                    this.statusList = data
                })
            },
            handleModelList(item) {
                this.current = 1
                this.model = item
                this.getData()
            },
            clearModelList() {
                this.current = 1
                this.model = null
                this.getData()
            },
            handleEdit(item) {
                this.editModalShow = true
                this.editInfo = item
            },
            handleEditCancel() {
                this.editModalShow = false
            },
            handleEditSubmit() {
                this.handleEditCancel()
                this.getData()
            },
            handleReplaceData(rowid) {
                this.terminalId = rowid
                this.showReplaceDataModal = true
            },
            handleReplaceDataCancel() {
                this.showReplaceDataModal = false
            },
            handleReplaceDataConfirm() {
                this.handleReplaceDataCancel()
            },
            handleUnbind(rowid) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '确认解绑全部数据',
                    okText: '确定',
                    cancelText: '取消',
                    loading: true,
                    closable: true,
                    onOk: () => {
                        this.$api.put(`terminals/${rowid}/commodities`, { commodities: [] }).then(() =>{
                            this.$Message.info('开始解绑数据')
                            this.getData()
                        }).catch(() => {}).finally(() => {
                            this.$Modal.remove();
                        })
                    }
                })
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    repeater_type: 'tag'
                }
                if (this.is_org) {
                    let len = this.nodeList.length
                    if (len) {
                        let department_id_list = []
                        this.nodeList.map((item) => {
                            department_id_list.push(item.department_id)
                        })
                        params.department_id_list = JSON.stringify(department_id_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.department_id = this.department_id
                }
                if (this.model) params.model_id = this.model.model_id
                if (this.status) params.state = this.status.status
                this.loading = true
                this.checkAll = false
                this.indeterminate = false

                this.$api.get('terminals/search', { params }).then(({ data, total_count, page, state_counts }) => {
                    if (page === this.current) {
                        let isAll = true
                        data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid)== -1) {
                                isAll = false
                            } else {
                                this.indeterminate = true
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }
                        this.list = data
                        this.total = total_count
                        if (this.status) {
                            this.total = state_counts[this.status.status]
                        }
                    }
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            getModelList() {
                let params = {
                    department_id: this.department_id
                }
                this.$api.get('terminals/models/list', { params }).then(({ data }) => {
                    this.modelList = data
                })
            }
        },
        mounted() {
            this.getData()
            this.data = this.handleRecursive(this.tree_data)
            this.getModelList()
            this.getStatusList()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
@deep: ~'>>>';
#smart-tag {
    position: relative;
    .status {
        margin-left: -15px;
    }
    .dropdown {
        position: relative;
        .close {
            display: none;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
        .tree {
            display: inline-block;
            max-width: 100px;
            vertical-align: middle;
        }
    }
    .check-group-wrap {
        position: relative;
        .check-group {
            width: calc(100% + 8px);
            .checkbox-wrap {
                position: relative;
                display: inline-block;
                margin: 0 8px 31px 0;
                padding-left: 0.7%;
                border: 1px solid #d3d3d3;
                width: calc(25% - 8px);
                padding-top: 13%;
                background: #fff;
                &:hover {
                    .mask {
                        opacity: 1;
                    }
                }
                .mask {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.08);
                    transition: opacity .3s ease;
                }
                .checkbox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-right: 0;
                    padding: 5px;
                }
                .checked {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    text-align: right;
                }
                .img-wrap-block {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 38%;
                    height: 100%;
                    background: #f6f7f9;
                    .device-status {
                        position: absolute;
                        left: 12px;
                        top: 11px;
                        padding: 0 6px;
                        border-radius: 9px;
                        color: #fff;
                        line-height: 16px;
                        font-size: 10px;
                        text-align: center;
                        white-space: nowrap;
                        .text {
                            display: block;
                            transform: scale(0.8);
                        }
                    }
                    .online {
                        background: #10c679;
                    }
                    .offline {
                        background: #ff1710;
                    }
                    .img-wrap {
                        position: absolute;
                        top: 31%;
                        bottom: 31%;
                        left: 10%;
                        right: 10%;
                    }
                    .img-wrap-vertical {
                        position: absolute;
                        top: 15%;
                        bottom: 19%;
                        left: 25%;
                        right: 25%;
                    }
                }
                .device-info {
                    position: absolute;
                    top: 50%;
                    left: 40%;
                    transform: translateY(-50%);
                    width: 58%;
                    min-width: 100px;
                    .device-name {
                        position: relative;
                        height: 33px;
                        font-weight: 700;
                        font-size: 10px;
                        width: calc(100% + 16px);
                        @{deep} .input {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: calc(100% - 16px) !important;
                            transform: translateY(-50%);
                            vertical-align: middle;
                        }
                        .name {
                            display: inline-block;
                            position: relative;
                            max-width: calc(100% - 24px);
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 33px;
                            vertical-align: middle;
                        }
                        .icon {
                            vertical-align: middle;
                        }
                    }
                    .device-id {
                        font-size: 10px;
                        font-weight: 700;
                    }
                    .device-site {
                        font-size: 10px;
                    }
                    .device-plan {
                        display: inline-block;
                        margin-top: 4px;
                        padding: 0 10px;
                        height: 28px;
                        max-width: 100px;
                        background: @primary-color;
                        border-radius: 4px;
                        color: #fff;
                        text-align: center;
                        line-height: 28px;
                        font-size: 12px;
                    }
                }
                .more {
                    position: absolute;
                    right: 3%;
                    bottom: 3%;
                    .icon-more {
                        border: 2px solid #2d2d2d;
                        border-radius: 50% 50%;
                    }
                }
            }
            @media screen and (min-width: 1600px) {
                .checkbox-wrap {
                    .device-info {
                        .device-name {
                            font-size: 14px;
                        }
                        .device-id {
                            font-size: 14px;
                        }
                        .device-site {
                            font-size: 12px;
                        }
                        .device-plan {
                            padding: 0 17px;
                            height: 35px;
                            line-height: 35px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>